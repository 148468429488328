import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import './Solar.css';

function Solar({state}) {
	const {solarData, solarHistory} = state.data;

	return (
		<div className='solar'>
			{solarHistory ?
				<ResponsiveContainer width='100%' height={300}>
					<LineChart data={solarHistory}>
						<XAxis
							dataKey='time'
							minTickGap={10}
							tickFormatter={timeStr => moment(timeStr).format('h:mm a')}
						/>
						<CartesianGrid strokeDasharray='3 3'/>
						<Tooltip
							labelFormatter={t => 'Time: ' + moment(t).format('h:mm a')}
							formatter={x => x + ' W'}
						/>

						<Line
							type='monotone'
							dataKey='actual_total'
							name='Power'
							stroke='#ff5900'
							strokeWidth={2}
							dot={false}
							animationDuration={1000}
						/>
					</LineChart>
				</ResponsiveContainer>
			:
				<p>Loading...</p>
			}

			{solarData && solarData.timestamp ?
				<div className='container'>
					<p>Total: {solarData.actual_total} W, {parseInt(solarData.actual_total / 5985 * 100)}%</p>

					<p>Today: {solarData.today_energy} kWh</p>

					<p>Updated: {solarData.timestamp.split(' ')[1]}</p>

					<p>Individual panels:</p>

					<div className='panels'>
						{Object.values(solarData.inverters).map((x, i) =>
							<>
								<div
									className='panel'
									style={{ backgroundColor: `hsl(21, 100%, ${x.power[0]/315*50}%)` }}
								>
									<div className='panel-label'>
										{x.power[0]}
									</div>
								</div>

								{i != 2 &&
									<div
										className='panel'
										style={{ backgroundColor: `hsl(21, 100%, ${x.power[1]/315*50}%)` }}
									>
										<div className='panel-label'>
											{x.power[1]}
										</div>
									</div>
								}
							</>
						)}
					</div>
				</div>
			:
				<p>Loading...</p>
			}
		</div>
	);
}

export default Solar;
