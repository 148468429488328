import React, { useState, useEffect } from 'react';
import Dimmer from './Dimmer';
import UpDownNum from './UpDownNum';
import './Thermo.css';

function ModeButton({device, setDeviceValue, num, name}) {
	return (
		<button
			onClick={() => setDeviceValue(device.serial, {...device.value, mode: num})}
			className={device.value.mode === num ? 'active' : ''}
		>
			{name}
		</button>
	);
}

function FanButton({device, setDeviceValue, num, name}) {
	return (
		<button
			onClick={() => setDeviceValue(device.serial, {...device.value, fan: num})}
			className={device.value.fan === num ? 'active' : ''}
		>
			{name}
		</button>
	);
}

function Thermo({state, setDeviceValue}) {
	const thermoStates = ['Idle', 'Heating', 'Cooling', 'Lockout', 'Error'];

	return (
		<div className='thermo'>
			{state.devices ?
				state.devices.filter(x => x.type == 'Thermostat').map(x =>
					<div key={x.serial} className='thermostat'>
						<div className='name'>
							{x.name}: {thermoStates[x.value.state]}
						</div>

						<div className='row'>
							<span className='spacetemp'>{x.value.spacetemp.toFixed(1)}°</span>

							<div className='modes'>
								<ModeButton device={x} setDeviceValue={setDeviceValue} num={0} name='Off' />
								<ModeButton device={x} setDeviceValue={setDeviceValue} num={1} name='Heat' />
								<ModeButton device={x} setDeviceValue={setDeviceValue} num={2} name='Cool' />
								<ModeButton device={x} setDeviceValue={setDeviceValue} num={3} name='Auto' />
							</div>
						</div>

						<div className='controls'>
							<UpDownNum
								device={x}
								setDeviceValue={(serial, v) => setDeviceValue(serial, {...x.value, heattemp: v})}
								value={x.value.heattemp}
								amount={0.5}
								active={x.value.mode === 1 || x.value.mode === 3}
								displayFunc={(x) => 'Heat: ' + x.toFixed(1) + '°'}
							/>
							<UpDownNum
								device={x}
								setDeviceValue={(serial, v) => setDeviceValue(serial, {...x.value, cooltemp: v})}
								value={x.value.cooltemp}
								amount={0.5}
								active={x.value.mode === 2 || x.value.mode === 3}
								displayFunc={(x) => 'Cool: ' + x.toFixed(1) + '°'}
							/>
						</div>

						<div className='row'>
							<span className='label'>Fan:</span>
							<div className='fan'>
								<FanButton device={x} setDeviceValue={setDeviceValue} num={0} name='Auto' />
								<FanButton device={x} setDeviceValue={setDeviceValue} num={1} name='On' />
							</div>
							<span className='fanstate'>{x.value.fanstate ? 'On' : 'Off'}</span>
						</div>

						<div className='row'>
							<span className='label'>Hum:</span>
							<span className='fanstate'>{x.value.hum}%</span>
							<span className='fanstate'>{x.value.hum_active ? 'On' : 'Off'}</span>
						</div>

						<div className='controls'>
							<UpDownNum
								device={x}
								setDeviceValue={(serial, v) => setDeviceValue(serial, {...x.value, hum_setpoint: v})}
								value={x.value.hum_setpoint}
								amount={1}
								active={true}
								displayFunc={(x) => 'Set: ' + x + '%'}
							/>
						</div>
					</div>
				)
			:
				<p>Loading...</p>
			}

			{state.devices ?
				state.devices.filter(x => x.serial == 'V001').map(x =>
					<div key={x.serial} className='thermostat'>
						<div className='name'>
							{x.name}: {thermoStates[x.state]}
						</div>

						<div className='modes'>
							<span className='spacetemp'>{x.reading || '?'}°</span>
							<button
								onClick={() => setDeviceValue(x.serial, {...x.value, active: false})}
								className={!x.value.active ? 'active' : ''}
							>
								Off
							</button>
							<button
								onClick={() => setDeviceValue(x.serial, {...x.value, active: true})}
								className={x.value.active ? 'active' : ''}
							>
								Heat
							</button>
						</div>

						<div className='controls'>
							<UpDownNum
								device={x}
								setDeviceValue={(serial, v) => setDeviceValue(serial, {...x.value, setpoint: v})}
								value={x.value.setpoint}
								amount={0.5}
								active={true}
								displayFunc={(x) => 'Heat: ' + x.toFixed(1) + '°'}
							/>
						</div>
					</div>
				)
			:
				<p>Loading...</p>
			}
		</div>
	);
}

export default Thermo;
