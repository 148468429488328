import React, { useState, useEffect } from 'react';
import { ToggleButtons } from './UI';
import './Security.css';

function AlarmButton({device, setDeviceValue, value, name}) {
	return (
		<button
			onClick={() => setDeviceValue(device.serial, value)}
			className={device.value === value ? 'active' : ''}
		>
			{name}
		</button>
	);
}

function LockButton({device, setDeviceValue, value, name}) {
	return (
		<button
			onClick={() => setDeviceValue(device.serial, value)}
			className={device?.value?.state === value ? 'active' : ''}
		>
			{name}
		</button>
	);
}

function Security({state, setDeviceValue, setSettingValue}) {
	const alarmStates = {disarmed: 'Disarmed', armed_home: 'Stay 🛡️', armed_away: 'Away 🛡️', pending: 'Pending', triggered: 'Triggered'};
	const lockStates = {locked: 'Locked', unlocked: 'Unlocked', not_fully_locked: 'Pending'};
	const unixnow = () => Math.floor(Date.now() / 1000);

	return (
		<div className='security'>
			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={(x) => setSettingValue('garage_door_opener', unixnow())}
				text='Garage'
				other={unixnow() - state.settings.garage_door < 3 ? 'Toggled' : ''}
			/>

			{state.devices ?
				state.devices.filter(x => x.type == 'DSCAlarm').map(x =>
					<div key={x.serial} className='alarm'>
						<div className='name'>
							{x.name}: {alarmStates[x.value]}
						</div>

						<div className='row'>
							<div className='arm'>
								<AlarmButton device={x} setDeviceValue={setDeviceValue} value={'disarmed'} name='Disarm' />
								<AlarmButton device={x} setDeviceValue={setDeviceValue} value={'armed_home'} name='Arm Stay' />
								<AlarmButton device={x} setDeviceValue={setDeviceValue} value={'armed_away'} name='Arm Away' />
							</div>
						</div>
					</div>
				)
			:
				<p>Loading...</p>
			}

			{state.settings ?
				<>
					<p>Phone: {state.settings.sees_phone ? 'Yes' : 'No'}</p>
					<p>Near: {state.settings.near_home ? 'Yes' : 'No'}</p>
					<p>Home: {state.settings.is_home ? 'Yes' : 'No'}</p>
				</>
			:
				<p>Loading...</p>
			}

			{state.devices ?
				state.devices.filter(x => x.type == 'ZigbeeLock').map(x =>
					<div key={x.serial} className='lock'>
						<div className='name'>
							{x.name}:
						</div>

						<div className='row'>
							<div className='lockbuttons'>
								<LockButton device={x} setDeviceValue={setDeviceValue} value={'LOCK'} name='Lock' />
								<LockButton device={x} setDeviceValue={setDeviceValue} value={'UNLOCK'} name='Unlock' />
								<span className='lockstate'>{lockStates[x?.value?.lock_state] || 'Unknown'}</span>
							</div>
						</div>
					</div>
				)
			:
				<p>Loading...</p>
			}
		</div>
	);
}

export default Security;
