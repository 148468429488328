import React, { useState, useEffect } from 'react';
import './Dimmer.css';
import Dashboard from './Dashboard';

function Dimmer({device, setDeviceValue, max}) {
	const [value, setValue] = useState(device.value);
	const [myval, setMyval] = useState(false);

	const set = (value) => {
		setDeviceValue(device.serial, value);
		setValue(value);
	};

	const handleChange = (e) => {
		console.log('handle change');
		set(e.target.valueAsNumber);
	}

	const handleMouseDown = (e) => {
		console.log('handle mouse dn');
		setMyval(true);
	}

	const handleMouseUp = (e) => {
		console.log('handle mouse up');
		setTimeout(() => setMyval(false), 1000);
	}

	return (
		<div className='dimmer'>
			<div className='left'>
				<button onClick={() => set(0)}>⚫️</button>
			</div>

			<div className='middle'>
				<input
					type='range'
					min='0'
					max={max}
					value={myval ? value : device.value}
					onChange={handleChange}
					onMouseDown={handleMouseDown}
					onMouseUp={handleMouseUp}
					onTouchStart={handleMouseDown}
					onTouchEnd={handleMouseUp}
				/>

				<div className='label'>
					{device.name}
				</div>
			</div>

			<div className='right'>
				<button onClick={() => set(max)}>🟡</button>
			</div>
		</div>
	);
}

export default Dimmer;
