import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ToggleButtons } from './UI';

function Settings({state, setSettingValue, pass, setPassWrapper, socketError}) {
	const [value, setValue] = useState('');
	const zigbeeDevices = state.devices.filter(x => x.type.includes('Zigbee'));

	const handleSubmit = (e) => {
		e.preventDefault();
		setPassWrapper(value);
	}

	return (
		<div className='settings'>
			{pass ?
				<div>
					<p>The password has been set.</p>
					<button onClick={() => setPassWrapper('')}>Log out</button>
				</div>
			:
				<div>
					<p>Enter controller password:</p>

					<form onSubmit={handleSubmit}>
						<input
							type='password'
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>

						<button type='submit'>Set</button>
					</form>
				</div>
			}

			{!!socketError && <p>Socket error: {socketError.message}</p>}

			<p/>

			{!!zigbeeDevices.length && zigbeeDevices.map(x =>
				<>
				{x.type.includes('Motion') || x.type.includes('Contact') ?
					<ToggleButtons
						value={''}
						values={[
							{name: 'Press', value: true},
						]}
						setValue={() => setSettingValue('test_motion', x.serial)}
						text=''
						other={x.name}
					/>
				:
					<p style={{fontSize: '1.75rem'}}>{x.name}</p>
				}
				<p>Last seen: {x.last_seen ? moment(x.last_seen * 1000).fromNow() : 'Never'}</p>
				</>
			)}

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={() => setSettingValue('test_priority_alert', true)}
				text=''
				other={'Test alert'}
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={() => setSettingValue('kill', true)}
				text=''
				other={'Kill server'}
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={() => setSettingValue('test_bark', true)}
				text=''
				other={'Test Bark'}
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={() => setSettingValue('real_bark', true)}
				text=''
				other={'Real Bark'}
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={() => setSettingValue('stop_bark', true)}
				text=''
				other={'Stop Bark'}
			/>
		</div>
	);
}

export default Settings;
