import React, { useState, useEffect } from 'react';
import Dimmer from './Dimmer';
import { ToggleButtons } from './UI';
import './Scenes.css';

function Scenes({state, setDeviceValue, setSettingValue}) {
	const on_vacation = state.settings.on_vacation;
	const guest_over = state.settings.guest_over;
	const motion_lock = state.settings.motion_lock;

	return (
		<div className='scenes'>
			<ToggleButtons
				value={guest_over}
				values={[
					{name: 'Yes', value: true},
					{name: 'No', value: false},
				]}
				setValue={(x) => setSettingValue('guest_over', x)}
				text='Guest over'
			/>

			<ToggleButtons
				value={on_vacation}
				values={[
					{name: 'Yes', value: true},
					{name: 'No', value: false},
				]}
				setValue={(x) => setSettingValue('on_vacation', x)}
				text='On vacation'
			/>

			<ToggleButtons
				value={motion_lock}
				values={[
					{name: 'On', value: true},
					{name: 'Off', value: false},
				]}
				setValue={(x) => setSettingValue('motion_lock', x)}
				text='Motion lock'
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={(x) => setSettingValue('script_bed_time', true)}
				text='Bed time'
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={(x) => setSettingValue('script_woke_up', true)}
				text='Woke up'
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={(x) => setSettingValue('script_show_time', true)}
				text='Show time'
			/>

			<ToggleButtons
				value={''}
				values={[
					{name: 'Press', value: true},
				]}
				setValue={(x) => setSettingValue('script_movie_time', true)}
				text='Movie time'
			/>
		</div>
	);
}

export default Scenes;
