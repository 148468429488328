import React, { useState, useEffect } from 'react';
import './UpDownNum.css';
import Dashboard from './Dashboard';

function UpDownNum({device, setDeviceValue, value, displayFunc, active, amount}) {
	const [myValue, setMyValue] = useState(false);

	const displayedValue = myValue ? myValue : value;

	const set = (v) => {
		setDeviceValue(device.serial, v);
		setMyValue(v);
	};

	useEffect(() => {
		console.log(myValue);
		if (!myValue) return;
		const timer = setTimeout(() => setMyValue(false), 5000);
		return () => clearTimeout(timer);
	}, [myValue]);

	return (
		<div className='updownnum'>
			<div className='left'>
				<button onClick={() => set(displayedValue - amount)} disabled={!active}>➖</button>
			</div>

			<div className='middle'>
				{displayFunc(displayedValue)}
			</div>

			<div className='right'>
				<button onClick={() => set(displayedValue + amount)} disabled={!active}>➕</button>
			</div>
		</div>
	);
}

export default UpDownNum;
