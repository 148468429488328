import React, { useState, useEffect } from 'react';
import { ToggleButtons } from './UI';
import Dimmer from './Dimmer';

function Plugs({state, setDeviceValue}) {
	return (
		<div className='plugs'>
			{state.devices ?
				state.devices.map(x =>
					<>
						{x.type == 'ZigbeePlug' &&
							<ToggleButtons
								value={x?.value?.state}
								values={[
									{name: 'On', value: 'ON'},
									{name: 'Off', value: 'OFF'},
								]}
								setValue={(y) => setDeviceValue(x.serial, y)}
								text={x.short_name}
							/>
						}
						{x.type == 'OutdoorPlugInSwitch' &&
							<ToggleButtons
								value={x.value}
								values={[
									{name: 'On', value: 100},
									{name: 'Off', value: 0},
								]}
								setValue={(y) => setDeviceValue(x.serial, y)}
								text={x.name}
							/>
						}
					</>
				)
			:
				<p>Loading...</p>
			}
		</div>
	);
}

export default Plugs;
