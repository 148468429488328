import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import moment from 'moment';
import './App.css';
import Dashboard from './Dashboard';
import Lights from './Lights';
import Plugs from './Plugs';
import Security from './Security';
import Solar from './Solar';
import Thermo from './Thermo';
import Scenes from './Scenes';
import Settings from './Settings';

function App() {
	const [pass, setPass] = useState(localStorage.getItem('pass', ''));

	const [page, setPage] = useState('dashboard');

	const [socket, setSocket] = useState(false);
	const [socketError, setSocketError] = useState(false);
	const [state, setState] = useState({devices: [], data: {}, settings: {}});

	useEffect(() => {
		const initSocket = (url, setSocket, setSocketError) => {
			const options = {
				auth: {token: pass},
				transports: ['websocket'],
				reconnectionDelayMax: 1000,
				timeout: 5000,
			};
			const s = io(url, options);
			s.on('devices', (x) => setState(prevState => ({...prevState, devices: x})));
			s.on('data', (x) => setState(prevState => ({...prevState, data: x})));
			s.on('settings', (x) => setState(prevState => ({...prevState, settings: x})));
			s.on('connect', (x) => setSocketError(false));
			s.on('connect_error', (x) => setSocketError(x));
			setSocket(s);
		};

		initSocket('wss://api.home.dns.t0.vc', setSocket, setSocketError);
	}, [pass]);

	const emit = (name, data) => {
		socket.emit(name, data);
	};

	const setDeviceValue = (serial, value) => {
		emit('setDeviceValue', {serial: serial, value: value});
	};

	const setSettingValue = (setting, value) => {
		emit('setSettingValue', {setting: setting, value: value});
	};

	const setPassWrapper = (pass) => {
		localStorage.setItem('pass', pass);
		setPass(pass);
	}

	return (
		<div className='app'>
			<div className='left'>
				<button
					className={page === 'dashboard' ? 'selected' : ''}
					onClick={() => setPage('dashboard')}
				>
					📊
				</button>

				<button
					className={page === 'scenes' ? 'selected' : ''}
					onClick={() => setPage('scenes')}
				>
					🎥
				</button>

				<button
					className={page === 'lights' ? 'selected' : ''}
					onClick={() => setPage('lights')}
				>
					💡
				</button>

				<button
					className={page === 'plugs' ? 'selected' : ''}
					onClick={() => setPage('plugs')}
				>
					🔌
				</button>

				<button
					className={page === 'security' ? 'selected' : ''}
					onClick={() => setPage('security')}
				>
					🛡️
				</button>

				<button
					className={page === 'solar' ? 'selected' : ''}
					onClick={() => setPage('solar')}
				>
					☀️
				</button>

				<button
					className={page === 'thermo' ? 'selected' : ''}
					onClick={() => setPage('thermo')}
				>
					🌡️
				</button>

				<button
					className={page === 'settings' ? 'selected' : ''}
					onClick={() => setPage('settings')}
				>
					⚙️
				</button>

				<center>
					{socket.connected ? ' 🟢' : ' 🔴'}
				</center>
			</div>
			<div className='right'>

				{page === 'dashboard' &&
					<Dashboard state={state} />
				}

				{page === 'scenes' &&
					<Scenes state={state} setDeviceValue={setDeviceValue} setSettingValue={setSettingValue} />
				}

				{page === 'lights' &&
					<Lights state={state} setDeviceValue={setDeviceValue} />
				}

				{page === 'plugs' &&
					<Plugs state={state} setDeviceValue={setDeviceValue} />
				}

				{page === 'security' &&
					<Security state={state} setDeviceValue={setDeviceValue} setSettingValue={setSettingValue} />
				}

				{page === 'solar' &&
					<Solar state={state} />
				}

				{page === 'thermo' &&
					<Thermo state={state} setDeviceValue={setDeviceValue}/>
				}

				{page === 'settings' &&
					<Settings state={state} setSettingValue={setSettingValue} pass={pass} setPassWrapper={setPassWrapper} socketError={socketError} />
				}
			</div>
		</div>
	);
}

export default App;
