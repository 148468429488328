import './UI.css';

export function ToggleButtons({value, values, setValue, text, other}) {
	return (
		<div className='ui togglebuttons'>
			{values.map(x =>
				<button
					key={x.name}
					onClick={() => setValue(x.value)}
					className={value === x.value ? 'active' : ''}
				>
					{x.name}
				</button>
			)}

			<span className='label'>
				{text}
			</span>

			<span className='otherlabel'>
				{other || ''}
			</span>
		</div>
	);
}

