import React, { useState, useEffect } from 'react';
import Dimmer from './Dimmer';

function Lights({state, setDeviceValue}) {
	return (
		<div className='lights'>
			{state.devices ?
				state.devices.map(x =>
					<>
						{x.type == 'WallDimmer' &&
							<Dimmer key={x.serial} device={x} setDeviceValue={setDeviceValue} max={100} />
						}
						{x.type == 'TempInWallPaddleDimmer' &&
							<Dimmer key={x.serial} device={x} setDeviceValue={setDeviceValue} max={100} />
						}
						{x.type == 'WallSwitch' &&
							<Dimmer key={x.serial} device={x} setDeviceValue={setDeviceValue} max={1} />
						}
					</>
				)
			:
				<p>Loading...</p>
			}
		</div>
	);
}

export default Lights;
